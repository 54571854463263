import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { db } from '../../Firebase';
import { getDocs, collection } from 'firebase/firestore';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function OriginalsList() {
  const [loading, setLoading] = useState(false);
  const [originalsDocs, setOriginalsDocs] = useState([]);

  useEffect(() => {
    setLoading(true);

    try {
      const originalsCollectionRef = collection(db, 'originals');
      getDocs(originalsCollectionRef)
        .then((querySnapshot) => {
          const docsData = querySnapshot.docs.map((doc) => doc.data());
          setOriginalsDocs(docsData);
        })
        .catch((error) => {
          console.error(error.message);
        });
    } catch (error) {
      console.error(error.message);
    }

    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="card-container">
            <Row>
          {originalsDocs.map((doc, index) => (
            <Col xs={12} md={4}>
                <Link to={`/admin/originals/${doc.slug}`}>
                <Card key={index} style={{width: '100%', maxWidth: '18rem', borderRadius: '.375rem'}}>
                <Container fluid className='p-0' style={{backgroundImage: `url(${doc.coverURL})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '15rem'}}></Container>
                <CardBody>
                    <CardTitle tag="h5">{doc.title}</CardTitle>
                    <CardText>{doc.author}</CardText>
                </CardBody>
                </Card>
                </Link>
            </Col>
          ))}
          </Row>
        </div>
      )}
    </div>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';

const ProductDetails = ({ product, onAddToCart }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);

  const handleVariantChange = (variant) => {
    setSelectedVariant(variant);
  };

  const handleAddToCart = () => {
    if (selectedVariant) {
      onAddToCart(selectedVariant.id, 1);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <img
            src={product.image.url}
            alt={product.name}
            className="product-details__image"
          />
        </Col>
        <Col md={6}>
          <Card className="product-details__card">
            <Card.Body>
              <Card.Title>{product.name}</Card.Title>
              <Card.Text>
                {product.description}
              </Card.Text>
              <Form.Group>
                <Form.Label>Select Size:</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) =>
                    handleVariantChange(
                      product.variant_groups[0].options.find(
                        (option) => option.name === e.target.value
                      )
                    )
                  }
                >
                  <option value="">Select...</option>
                  {product.variant_groups[0].options.map((option) => (
                    <option key={option.id} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button
                variant="primary"
                disabled={!selectedVariant}
                onClick={handleAddToCart}
              >
                Add to Cart
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ProductDetails.propTypes = {
  product: PropTypes.object,
  onAddToCart: PropTypes.func,
};

export default ProductDetails;

import { Container, Form, Button, Alert } from 'react-bootstrap'
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';

const serviceKey = process.env.REACT_APP_EMAILJS_SERVICE_KEY
const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

export default function ContactForm() {
    const form = useRef()
    const [emailMessage, setEmailMessage] = useState("")
    const [emailErrorMessage, setEmailErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const sendEmail = async (e) => {
        e.preventDefault();

        setLoading(true)
    
        try {
            await emailjs.sendForm(serviceKey, 'template_hv4j2oe', form.current, publicKey)
            .then((result) => {
              console.log(result.text);
              setEmailMessage('Thank you for contacting us. We will be in touch soon!')
          })
        } catch (error) {
            console.log(error.text);
              setEmailErrorMessage('There seems to be an error. Please refresh the page and try again.')
        } finally {
            setLoading(false)
            messageReset()
        }
      };

    const messageReset = () => {
        setTimeout(() => {
            setEmailErrorMessage("")
            setEmailMessage("")
        }, 3500)
    }

  return (
    <Container className='bodyFont mt-5' style={{maxWidth: '40rem'}}>
        {emailMessage && (<Alert>{emailMessage}</Alert>)}
        {emailErrorMessage && (<Alert style={{background: 'red'}}>{emailMessage}</Alert>)}
        <h1 align='center'>Contact Us</h1>
        <p align='center'>Reach out to use for any questions or comments.</p>
        <Form className='w-100' onSubmit={sendEmail} ref={form}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control type="name" name="user_name" placeholder="Enter your name" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" name="user_email" placeholder="Enter your email" required />
                <Form.Text className="text-muted">
                We'll never share your email with anyone else.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMessage">
                <Form.Label>Your Message</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Leave a message."
                    name="message"
                    style={{ height: '100px' }}
                    />
                    required
            </Form.Group>
            
            <Button variant="warning" type="submit" value="Send" className='w-100' disabled={loading}>
                {loading ? "Sending Email..." : "Submit"} 
            </Button>
        </Form>
    </Container>
  )
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from 'components/Loading/Loading';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  
} from 'reactstrap';
import { Link } from 'react-router-dom';

export default function ChapterList(props) {
    const [webtoonChapterList, setWebtoonChapterListData] = useState(null);
    const [loading, setLoading] = useState(false);

    {/*
    useEffect(() => {
        pullWebtoonChapterList();
    }, []);

    const pullWebtoonChapterList = async () => {
        setLoading(true);  

    const apiKey = "64c5bf9215msh7b5e2fda8339e13p190d26jsn0fecee37f42f"
    
    
    const options = {
      method: 'GET',
      url: 'https://manga-scrapper.p.rapidapi.com/chapters/',
      params: {
        provider: 'omega',
        webtoon: `${props.slug}`,
      },
      headers: {
        'X-RapidAPI-Key': apiKey,
        "Access-Control-Allow-Origin": "https://onyxblackmanhwa.com",
        'X-RapidAPI-Host': "manga-scrapper.p.rapidapi.com",
        'Access-Control-Allow-Credentials': "*",
        'Access-Control-Allow-Headers': 'Content-Type, Authorization'
      }
    };

      try {
      const response = await axios.request(options);
      const sortedChapters = response.data.sort((a, b) =>  b.chapterNum - a.chapterNum );
      console.log(sortedChapters);
      setWebtoonChapterListData(sortedChapters);
      } catch (error) {
        console.error(error);
      }finally {
        setLoading(false);
      }
    };
  */}

    if(!props.slug){
        return(
            <>
            <div className="content">
            <p>You need to select a manhwa.</p>
            <Link to="/admin">
                <Button to>Dashboard</Button>
            </Link>
            </div>
            </>
        )
      }

      console.log(props.chapterList)
      console.log(props)
  return (
    <div className='content'>
      <Row>
        <Col md="12">
            {loading ? (
              <>
                <Loading />
              </>
            ) : (
              <>
              <Card>
                {!props.slug || !props.chapterList ? (
                  <>
                    <p>No data</p>
                  </>
                ) : (
                  <>
                    <CardHeader>
                      <CardTitle tag="h4">Chapter List</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Chapter</th>
                            <th>Title</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.chapterList.map((chapter, index) => (
                            <tr key={index}>
                              <td>{chapter.title}</td>
                              <td>
                              <Link to={`/admin/manhwa/${props.slug}/chapter/${chapter.chapter_id}`}><p>{chapter.title}</p></Link>
                              </td>
                              <td>{chapter.release_date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </>
                )}
                </Card>
              </>
            )}
        </Col>
      </Row>
    </div>
  );
}

import { useState } from "react";
import Cart from "./Cart";
import { Nav } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { useCart } from "contexts/CartContext";
import PolicyDisplay from "components/PolicyDisplay/PolicyDisplay";

const CartNav = ({ cart, onUpdateCartQty, onRemoveFromCart, onEmptyCart }) => {
    const { loading } = useCart()
    const [isCartVisible, setCartVisible] = useState(false);
  
    const renderOpenButton = () => (
      <div className="nav__cart-btn--open mb-5 p-2 w-auto h-auto d-flex justify-content-center">
        <p>
        <i className="tim-icons icon-cart" style={{fontSize: '1.5rem'}}/>
        {cart !== null ? <span>{" "}{cart.total_items}</span> : ''}
        </p>
      </div>
    );

    const renderCloseButton = () => (
      <div className="nav__cart-btn--close p-2 w-auto h-auto d-flex justify-content-center">
        <p>
        <i className="tim-icons icon-simple-remove" style={{fontSize: '1.5rem'}}/>
        {' '}
        </p>
      </div>
    );

    if(loading){
      return(
        <p style={{fontSize: '14px'}}>Loading Cart...</p>
      )
    }
  
    return (
      <>
      <Nav fluid className="nav">
      <div className="nav__cart" onClick={() => setCartVisible(!isCartVisible)}>
          { !isCartVisible ? renderOpenButton() : renderCloseButton() }
      </div>
        {isCartVisible &&
        <Cart
            cart={cart}
            onUpdateCartQty={onUpdateCartQty}
            onRemoveFromCart={onRemoveFromCart}
            onEmptyCart={onEmptyCart}
        />
        }
      </Nav>
      </>
    );
  };
  
  export default CartNav;
import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { UserContextData } from 'contexts/UserContext';
import Loading from 'components/Loading/Loading';
import { Link } from 'react-router-dom';


export default function FavoritesList(props) {
  const [favoritesData, setFavoritesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const maxFavoritesToShow = props.maxFavoritesToShow; // Set the maximum number of favorites to display

  const { userDoc } = UserContextData();

  useEffect(() => {
    setLoading(true);
    if (userDoc) {
      // Assuming 'favorites' is the array in userDoc
      setFavoritesData(userDoc.favorites || []);
    }
    setLoading(false);
  }, [userDoc]);

  if (loading) {
    return <Loading />;
  }

  if (favoritesData.length === 0) {
    return (
    <Container className='mb-3 p-0'>
        <h2 className='mb-2'>Save Your Favorites</h2>
        <p>Nothing added yet. Easily access your favorite series.</p>
      </Container>
    );
  }

  // Slice the favoritesData array to limit the number of favorites displayed
  const displayedFavorites = favoritesData.slice(0, maxFavoritesToShow);

  return (
    <div>
      <Container className='mb-3 p-0'>
        <h2 className='mb-2'>Your Favorites</h2>
        <p>Easily access your favorite series.</p>
      </Container>
      <ListGroup>
        {displayedFavorites.map((favorite) => (
          <ListGroupItem key={favorite.slug} style={{background: 'none', border: 'none'}}>
            <Link
              to={
                favorite.isOriginal
                  ? `/admin/originals/${favorite.slug}`
                  : `/admin/manhwa/${favorite.slug}`
              }
            >
                  <p style={{textDecoration: 'underline'}}>{favorite.title}</p>
            </Link>
          </ListGroupItem>
        ))}
      </ListGroup>
      {/* Check if there are more favorites to display */}
      {favoritesData.length > maxFavoritesToShow && (
        <Container className='mb-3 p-0'>
          <Link to='/admin/user-profile'>
            <Button>View All</Button>
          </Link>
        </Container>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { Container, Form, Button, Spinner, Alert, Card } from "react-bootstrap";
import { UserContextData } from "contexts/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { getDocs, collection, where } from "firebase/firestore";
import { db } from "../../Firebase";

export default function RegisterForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { currentUser, register, navLink, registerErrorMessage } =
    UserContextData();

  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    setLoading(true);

    // Check if the username is already in use
    const usernameExists = await checkUsernameExists(username);

    if (usernameExists) {
      console.log("Username in use already.");
      setErrorMessage("Username in use already.");
      errorReset();
      setLoading(false);
      return;
    }

    // Checks for password and email
    if (!email || !password || !username || !firstName || !lastName) {
      setErrorMessage("Please enter all your information.");
      setLoading(false);
      errorReset();
      return;
    }

    // Checks for matching passwords
    if (password !== confirmPassword) {
      setErrorMessage(
        "Passwords must match. Please ensure your password matches."
      );
      setLoading(false);
      errorReset();
      return;
    }

    // Calls register function from the user context file
    try {
      await register(email, password, firstName, lastName, username);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Resets error for user
  const errorReset = async () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };

  // Function to check if a username exists in the Firestore collection
  async function checkUsernameExists(username) {
    const usersCollection = collection(db, "users");
    const querySnapshot = await getDocs(usersCollection);

    for (const doc of querySnapshot.docs) {
      const userData = doc.data();
      if (userData.username.toLowerCase() === username.toLowerCase()) {
        // Username already exists
        return true;
      }
    }

    // Username doesn't exist
    return false;
  }

  if (currentUser) {
    navigate("/admin/dashboard");
  }

  if (currentUser) {
    return (
      <div className="content">
        <p>Redirecting to user dashboard...</p>
      </div>
    );
  }

  return (
    <Card style={{ maxWidth: "30" }}>
      <Card.Body>
        <Card.Title>
          <h2>Register</h2>
          <p>
            Get registered and start reading the best manga and manhwa available
            online for FREE now!
          </p>
        </Card.Title>

        <Form onSubmit={handleSignUp} className="w-100">
          {registerErrorMessage ? (
            <Alert style={{ backgroundColor: "red" }}>
              {registerErrorMessage}
            </Alert>
          ) : (
            <></>
          )}
          {errorMessage ? (
            <Alert style={{ backgroundColor: "red" }}>{errorMessage}</Alert>
          ) : (
            <></>
          )}
          <Form.Group controlId="formBasicFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>

          {!loading ? (
            <Button
              className="w-100 mt-3"
              variant="primary"
              type="submit"
              disabled={currentUser}
            >
              Submit
            </Button>
          ) : (
            <>
              <Container align="center">
                <Spinner animation="grow" />
                <p>Creating profile, please wait...</p>
              </Container>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
}

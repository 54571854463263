import { useContext, createContext, useState, useEffect } from "react";
import { signInWithEmailAndPassword, signOut, getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc, getDoc, getDocs, collection, where } from "firebase/firestore";
import { db } from "../Firebase";

const UserContext = createContext(null)

export function UserContextProvider({children}) {
    // GLOBAL USER STATE
    const auth = getAuth()
    
    // User Data
    const [currentUser, setCurrentUser] = useState(null)
    const [userDoc, setUserDoc] = useState(null)

    // Account Data
    const [accountStatus, setAccountStatus] = useState(null)

    // Loading Screen
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [registerErrorMessage, setRegisterErrorMessage] = useState("")
    const [loginErrorMessage, setLoginErrorMessage] = useState("")
    const [resetErrorMessage, setResetErrorMessage] = useState("")


    // Navigation State
    const [navLink, setNavLink] = useState("")

    // Checks for current user on load
    
    useEffect(() => {
        setLoading(true)

        if(currentUser){
            console.log('User logged in already.', currentUser)
            return
        }
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          setCurrentUser(user);
    
          if (user) {
            try {
              const userDocRef = doc(db, 'users', user.uid);
              const docSnapshot = await getDoc(userDocRef);
    
              if (docSnapshot.exists()) {
                setUserDoc(docSnapshot.data());
                console.log(userDoc, currentUser)
              }
            } catch (error) {
              console.error(error.message);
            }
          }
    
          setLoading(false);
        });
    
        return () => unsubscribe(); // Cleanup on unmount
    }, [currentUser]);

    // Resets error for user 
    const errorReset = async () => {
        setTimeout(() => {
          setErrorMessage("")
          setRegisterErrorMessage("")
          setLoginErrorMessage("")
          setResetErrorMessage("")
        }, 3000)
    }

    // Checks for user on load
    const navReset = async () => {
      setTimeout(() => {
        setNavLink("")
      }, 3000)
  }

    // Register a new user
    const register = async (email, password, firstname, lastname, username) => {
      setLoading(true)
           
      await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          setCurrentUser(user);
          setDoc(doc(db, 'users', user.uid), {
              email: email,
              accountID: user.uid,
              firstname: firstname,
              lastname: lastname,
              username: username,
              favorites: [],
              aboutMe: '',
              userimage: ''
          });
  
          setNavLink('/admin/user-profile/')
          
      })
      .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          // ..
          setRegisterErrorMessage("Error. Please try again.")
          setLoading(false)
          errorReset()
          return
      });
      setLoading(false)
  }
  

    // Login a user
    const login = async (email, password) => {
        setLoading(true)
        
        // Checks for a user before attempting login 
        if(currentUser != null){
            console.log('User already logged in...')
            setLoading(false)
            return
        };
        
        // Signs in user
        await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          setCurrentUser(user)
          setNavLink("/admin/dashboard")
          navReset()
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode, error.message)
          // ..
          setLoginErrorMessage("Incorrect information. Please try again.")
          setLoading(false)
          errorReset()
          return
       
        });
        
        setLoading(false)
    }

    // Logout a user
    const logout = async () => {
        setLoading(true);
        
        await signOut(auth)
        .then(() => {
            // Sign-out successful.
            setCurrentUser(null)
            setUserDoc(null)
          }).catch((error) => {
            // An error happened.
            console.log(error)
            setErrorMessage("Error logging out user. Please refresh page and try again.")
          });

        setLoading(false);
    }

    // Reset a user password
    const reset = async (email) => {
      setLoading(true);

      await sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        console.log('Email has been sent.')
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        console.log(errorCode, error, errorMessage)
        setResetErrorMessage("There's been an error. Please try again.")
        errorReset()
      });

      setLoading(false);
      }

    return(
        <UserContext.Provider value={
            {
            currentUser,
            userDoc,
            login,
            logout,
            register,
            reset,
            setUserDoc,
            navLink,
            accountStatus,
            errorMessage,
            registerErrorMessage,
            loginErrorMessage,
            resetErrorMessage,
            loading
            }
        }>{children}</UserContext.Provider>
    )
}

export const UserContextData = () => {
    return useContext(UserContext)
}
import OriginalsList from 'components/Originals/OriginalsList'
import React from 'react'
import { Container } from 'react-bootstrap'

export default function Originals() {
  return (
    <div className='content'>
      <Container className="mb-3 p-0">
              <h2 className="mb-2">Onyx Black Manhwa Originals</h2>
              <p>Read our exclusive orginal series.</p>
            </Container>
      <OriginalsList />
    </div>
  )
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Container, Image, Form } from "react-bootstrap";

const ProductItem = ({ product, onAddToCart, loading }) => {
  const { description } = product;

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState({});


  const isAllVariantsSelected = Object.keys(selectedVariants).length === product.variant_groups.length;

  const handleAddToCart = () => {
    // Check if all variant groups have been selected
    if (isAllVariantsSelected) {
      // Create a variant data object with selected variants
      const variantData = {};
      product.variant_groups.forEach((group) => {
        variantData[group.id] = selectedVariants[group.id];
      });
  
      // Add the product to the cart with the variant data
      onAddToCart(product.id, 1, variantData);
      console.log(product.id, 1, variantData)
    } else {
      // Inform the user to select all variants before adding to cart
      // You can show a message or disable the "Add to Cart" button
    }
  };
  
  

  const handleVariantChange = (groupId, variant) => {
    setSelectedVariants({
      ...selectedVariants,
      [groupId]: variant,
    });
  };

  return (
    <Card className="product__card" style={{ width: "100%", maxWidth: '35rem'}}>

      <Container fluid className="p-0" style={{ height: "17rem" }}>
        <Image
          referrerPolicy="no-referrer"
          fluid
          className="product__image"
          src={product.image?.url}
          alt={product.name}
          style={{ width: "100%", height: "100%", objectFit: 'cover' }}
        />

      </Container>
      <Card.Body className="product__info w-100">
        <Card.Title>
          <h4 className="product__name">{product.name}</h4>
        </Card.Title>
        <Card.Text>
          <p className="product__description">
            {/* product description stripped of html tags */}
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </p>
        </Card.Text>
        <div className="product__details">
          <p className="product__price">{product.price.formatted_with_symbol}</p>
        </div>
        <Button
          name="More info"
          className="product__btn"
          onClick={() => setShowMoreInfo(!showMoreInfo)}
        >
          Options
        </Button>
        {showMoreInfo && (
          <div className="product__more-info">
          {product.variant_groups.map((group) => (
            <Form.Group key={group.id}>
              <Form.Label>Select {group.name}:</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) =>
                  handleVariantChange(group.id, e.target.value)
                }
              >
                <option value="">Select...</option>
                {group.options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          ))}
          {}
          <Button
            variant="primary"
            disabled={!isAllVariantsSelected || loading}
            onClick={handleAddToCart}
          >
           {loading ? 'Adding' : 'Add to Cart'}
          </Button>
        </div>
        )}
      </Card.Body>
    </Card>
  );
};

ProductItem.propTypes = {
  product: PropTypes.object,
  onAddToCart: PropTypes.func,
  onSelectProduct: PropTypes.func,
};

export default ProductItem;

// CartContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import commerce from 'lib/commerce';


const CartContext = createContext();

export function CartProvider({ children }) {
  const [cart, setCart] = useState({});
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState()

  useEffect(() => {
    fetchCart()
  }, [])

  const [orderData, setOrderData] = useState({
    merchant: {},
    products: [],
    cart: {},
    isCartVisible: false,
    order: {},
  })

   /**
     * Retrieve the current cart or create one if one does not exist
     * https://commercejs.com/docs/sdk/cart
     */
   const fetchCart = async () => {
    setLoading(true)
    await commerce.cart.retrieve().then((cart) => {
        setCart(cart);
        console.log(cart)
    }).catch((error) => {
        console.log('There was an error fetching the cart', error);
    });
    setLoading(false)
    }

  return (
    <CartContext.Provider value={{ cart, orderData, products, setProducts, setCart, setOrderData, loading, fetchCart, setPurchaseOrder, purchaseOrder }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
import React, { Component, useState } from 'react';
import CartItem from './CartItem';
import PropTypes from 'prop-types';
import { 
  Button,
  Container,
  Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Cart = ({ cart, onUpdateCartQty, onRemoveFromCart, onEmptyCart }) => {
  const [emptyingCart, setEmptyingCart] = useState(false);

  console.log(cart)

  const handleEmptyCart = async () => {
    setEmptyingCart(true); // Set emptyingCart state to true when emptying cart

    try {
      await onEmptyCart();
    } catch (error) {
      console.error('There was an error emptying the cart', error);
    } finally {
      setEmptyingCart(false); // Set emptyingCart state back to false when the cart is empty
    }
  };

  const renderEmptyMessage = () => {
    if (cart.total_unique_items > 0) {
      return;
    }

    return (
      <p className="cart__none">
        You have no items in your shopping cart, start adding some!
      </p>
    );
  }

  const renderItems = () => (
    cart.line_items.map((lineItem) => (
      <CartItem
        item={lineItem}
        key={lineItem.id}
        onUpdateCartQty={onUpdateCartQty}
        onRemoveFromCart={onRemoveFromCart}
        onEmptyCart={onEmptyCart}
        className="cart__inner"
        selectedVariant={lineItem.selectedVariant} // Pass selectedVariant here

      />
    ))
  );

  const renderTotal = () => (
    <Container className="cart__total">
      <p className="cart__total-title">Subtotal:</p>
      <p className="cart__total-price">{cart.subtotal.formatted_with_symbol}</p>
    </Container>
  );

  const emptyCartButtonText = emptyingCart ? 'Emptying Cart...' : 'Empty Cart';


  return (
    <Card className="cart">
      <Card.Body>
      <Card.Title>
        <h4 className="cart__heading">Your Shopping Cart</h4>
      </Card.Title>
      { renderEmptyMessage() }
      { renderItems() }
      { renderTotal() }
      <Container className="cart__footer">
      {cart.line_items.length != 0 && (
        <>
        <Button className="cart__btn-empty" onClick={handleEmptyCart} disabled={emptyingCart}>{emptyCartButtonText}</Button>
        <Link to="/checkout">
          <Button variant="secondary" className="cart__btn-checkout">Checkout</Button> 
        </Link>
        </>
      )}
      </Container>
      </Card.Body>
    </Card>
  );
};

Cart.propTypes = {
    cart: PropTypes.object,
    onEmptyCart: () => {},
};

export default Cart;
import React, { useState, useEffect } from 'react'
import ProductsList from 'components/ProductsList/ProductsList'
import ProductDetails from 'components/ProductDetails/ProductDetails';
import Loading from 'components/Loading/Loading';
import CartNav from 'components/Cart/CartNav';
import commerce from '../lib/commerce';
import { useCart } from 'contexts/CartContext';
import { Col, Container, Row } from 'react-bootstrap';
import PolicyDisplay from 'components/PolicyDisplay/PolicyDisplay';
import ShopifyBuyPage from 'components/Shopify/ShopifyBuyPage';
import ShopifyBuyButton from 'components/Shopify/ShopifyBuyButton';

export default function Shop() {
    const { cart, setCart, products, setProducts, setPurchaseOrder, purchaseOrder } = useCart();
    const [loading, setLoading] = useState(false);

    const [addLoading, setAddLoading] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
      fetchProducts();
    }, []);
  
    // Clears purchaseOrder state if there is one
    useEffect(() => {
      if(purchaseOrder){
        setPurchaseOrder()
      }
    },)
    
    /**
     * Fetch products data from Chec and stores in the products data object.
     * https://commercejs.com/docs/sdk/products
     */
    const fetchProducts = async () => {
      setLoading(true);

      await commerce.products.list().then((products) => {
        setProducts(products.data);
        setLoading(false);
      }).catch((error) => {
        console.log('There was an error fetching the products', error)
      });

      setLoading(false);
    }

    /**
     * Adds a product to the current cart in session
     * https://commercejs.com/docs/sdk/cart/#add-to-cart
     *
     * @param {string} productId The ID of the product being added
     * @param {number} quantity The quantity of the product being added
     */
    const handleAddToCart = async (productId, quantity, variantId, selectedVariant) => {
      setAddLoading(true); // Set loading state to true when adding to cart

        try {
          const item = await commerce.cart.add(productId, quantity, variantId, selectedVariant);
          setCart(item.cart);
          console.log(item)
        } catch (error) {
          console.error('There was an error adding the item to the cart', error);
        } finally {
          setAddLoading(false); // Set loading state back to false
        }
    }

    /**
     * Updates line_items in cart
     * https://commercejs.com/docs/sdk/cart/#update-cart
     *
     * @param {string} lineItemId ID of the cart line item being updated
     * @param {number} newQuantity New line item quantity to update
     */
    const handleUpdateCartQty = async (lineItemId, quantity) => {
        await commerce.cart.update(lineItemId, { quantity }).then((resp) => {
        setCart(resp.cart);
        }).catch((error) => {
        console.log('There was an error updating the cart items', error);
        });
    }

    /**
     * Removes line item from cart
     * https://commercejs.com/docs/sdk/cart/#remove-from-cart
     *
     * @param {string} lineItemId ID of the line item being removed
     */
    const handleRemoveFromCart = async (lineItemId) => {
        await commerce.cart.remove(lineItemId).then((resp) => {
          setCart(resp.cart);
        }).catch((error) => {
          console.error('There was an error removing the item from the cart', error);
        });
      }

      /**
     * Empties cart contents
     * https://commercejs.com/docs/sdk/cart/#remove-from-cart
     */
    const handleEmptyCart = async () => {
        await commerce.cart.empty().then((resp) => {
        setCart(resp.cart);
        }).catch((error) => {
        console.error('There was an error emptying the cart', error);
        });
    }

    const handleProductSelect = (product) => {
      setSelectedProduct(product);
    };

    if(loading){
      return(
        <div className="content">
          <Loading />
        </div>
      )
  };
  
    if(!loading && products.length === 0){
      return(
          <div className='content'>
              <p>No products available currently...</p>
          </div>
      )
  }

  if(!loading && !products){
    return(
        <div className='content'>
            <p>No products available currently...</p>
        </div>
    )
}
  
  return (
    <div className='content'>
      <Container className="mb-3 p-0">
        <h2 className="mb-2">Shop Onyx Black Apparel</h2>
        <p>Shop our catalog and support Onyx Black Manhwa.</p>
      </Container>
      <CartNav 
          cart={cart}
          onUpdateCartQty={handleUpdateCartQty}
          onRemoveFromCart={handleRemoveFromCart}
          onEmptyCart={handleEmptyCart}
      />
      <Row>

        <Container fluid>
      <ProductsList 
          products={products}
          onAddToCart={handleAddToCart}
          onSelectProduct={handleProductSelect} // Make sure onSelectProduct is passed here
          loading={addLoading} // Pass loading prop to ProductsList
      />
      </Container>
      </Row>
    {/*}
      <ShopifyBuyButton display={'show'} />
    {*/}
      <PolicyDisplay />
      {selectedProduct && (
        <ProductDetails
          product={selectedProduct}
          onAddToCart={handleAddToCart}
        />
      )}
    </div>
  )
}

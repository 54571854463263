// react bootstrap components
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";

import OrginalSpotlights from "components/Originals/OrginalSpotlights";
import FavoritesList from "components/FavoritesList/FavoritesList";
import SeriesUpdates from "components/SeriesUpdates/SeriesUpdates";

function Dashboard() {
  return (
    <>
      <div className="content">
        <Container fluid className='p-0'>
            <Row>
                <Col md={6}>
                  <OrginalSpotlights />
                </Col>
                <Col md={6}>
                  <FavoritesList maxFavoritesToShow={6} />
                </Col>
            </Row>
            {/* Add the SeriesUpdates component }
            <Row>
              <Col md={12}>
                <SeriesUpdates />
              </Col>
            </Row>
              <Container className="mt-3 mb-3 justify-content-center align-items-center d-flex" fluid style={{height: '15rem', background: 'black', borderRadius: '.375rem'}}>Ad Space</Container>
          {*/}
        </Container>
      </div>
    </>
  );
}

export default Dashboard;

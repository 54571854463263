import Browse from "views/Browse";
import Contact from "views/Contact";
import Dashboard from "views/Dashboard.js";
import Originals from "views/Originals";
import Shop from "views/Shop";
import UserProfile from "views/UserProfile.js";


var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-components",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/browse",
    name: "Browse",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-compass-05",  
    component: <Browse />,
    layout: "/admin",
  },
  {
    path: "/originals/",
    name: "Originals",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-atom",
    component: <Originals />,
    layout: "/admin",
  },
  {
    path: "/shop/",
    name: "Shop",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-gift-2",
    component: <Shop />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: <UserProfile />,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "Contact",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-support-17",
    component: <Contact />,
    layout: "/admin",
  },
];
export default routes;

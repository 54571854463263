import React from 'react'
import { Container } from 'react-bootstrap'

export default function Error() {
  return (
    <Container>
        <h2>Hey there my friend...you've reached an end.</h2>
        <p>Head back to the home page or dashboard.</p>
    </Container>
  )
}

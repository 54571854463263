import React, { useState } from 'react';
import HomeNavbar from 'components/HomeNavbar/HomeNavbar';
import LoginForm from 'components/Forms/LoginForm';
import RegistrationForm from 'components/Forms/RegisterForm';
import {
  Container,
  Row,
  Col,
  Image,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import ONBFavicon from '../assets/img/ONBFavicon.png'
import Footer from 'components/Footer/Footer';

export default function Home() {
  const [showLoginForm, setShowLoginForm] = useState(true);

  const toggleForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  return (
    <>
    <Helmet>
        <title>Onyx Black Manhwa || Read Manga and Manhwa for Free</title>
        <meta
          name="description"
          content="Read manga and manhwa for free on our platform. Explore a vast collection of comics from various genres."
        />
        <meta property="og:title" content="Read Manga and Manhwa for Free" />
        <meta
          property="og:description"
          content="Read manga and manhwa for free on our platform. Explore a vast collection of comics from various genres."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://onyxblackmanhwa.com" />
        <meta
          property="og:image"
          content={ONBFavicon}
        />
      </Helmet>
      <HomeNavbar />
      <Container style={{minHeight: '100vh'}}>
        <Row>
          <Col xs={12} md={5} className="mb-5 mb-md-0">
            <Image
              src={
                showLoginForm
                  ? 'https://firebasestorage.googleapis.com/v0/b/manga-app-f1b52.appspot.com/o/originals-art%2Fkei%CC%84.png?alt=media&token=9798fe93-47e8-47ee-b22f-d74ef7fa89ab'
                  : 'https://firebasestorage.googleapis.com/v0/b/manga-app-f1b52.appspot.com/o/originals-art%2Fmiyeon.png?alt=media&token=73c8558e-56da-4897-bdd5-fc59099863ca'
              }
              style={{ height: '100%', maxHeight: '15rem' }}
              alt={showLoginForm ? 'Kei' : 'Miyeon'}
            />
          </Col>
          <Col xs={12} md={7}>
            <Container>
              {showLoginForm ? <LoginForm /> : <RegistrationForm />}
              <Container fluid className="p-0 mt-5">
                <p onClick={toggleForm} style={{textDecoration: 'underline', cursor: 'pointer'}}>
                  {showLoginForm ? 'Register A New Account' : 'Login To An Existing Account'}
                </p>
              </Container>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <div>
        <h3>Privacy Policy</h3>
        <p>Onyx Black Manhwa</p>
        <p>Address: Dallas, TX</p>
      </div>
      <div>
        <h4>Introduction</h4>
        <p>Welcome to Oynx Black Manhwa ("we", "our", or "us"). Your privacy is essential to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website or services.</p>

        <h4>Information We Collect</h4>
        <p>Personal Information: When you interact with our website or make a purchase, we may collect personal information such as your name, address, email address, and payment information.</p>
        <p>Usage Information: We may collect information about how you use our website, including your browsing history, IP address, and device information.</p>

        <h4>How We Use Your Information</h4>
        <p>We use the collected information for purposes, including but not limited to:</p>
        <ul>
          <li>Processing and fulfilling orders.</li>
          <li>Improving our website and services.</li>
          <li>Communicating with you about your orders and providing customer support.</li>
          <li>Sending you promotional offers or newsletters if you have opted in.</li>
        </ul>

        <h4>Sharing Your Information</h4>
        <p>We may share your information with trusted third parties, such as payment processors or shipping companies, to facilitate your orders. We do not sell your personal information.</p>

        <h4>Security</h4>
        <p>We take reasonable measures to protect your information from unauthorized access or disclosure.</p>

        <h4>Your Choices</h4>
        <p>You can opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in the emails.</p>

        <h4>Changes to this Privacy Policy</h4>
        <p>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons.</p>
      </div>
    </div>
  );
};

const ShippingPolicy = () => {
  return (
    <div>
      <div>
        <h3>Shipping Policy</h3>
        <p>Onyx Black Manhwa</p>
        <p>Address: Dallas, TX</p>
      </div>
      <div>
        <h4>Shipping Information</h4>
        <p>We offer shipping to addresses within the United States.</p>
        <p>Orders are typically processed within 4 business days and shipped within 7 business days.</p>
        <p>Shipping costs may vary based on the weight and destination of the package.</p>
        <p>Tracking information will be provided for your convenience.</p>
      </div>
    </div>
  );
};

const ReturnPolicy = () => {
  return (
    <div>
      <div>
        <h3>Return Policy</h3>
        <p>Onyx Black Manhwa</p>
        <p>Address: Dallas, TX</p>
      </div>
      <div>
        <h4>Returns and Exchanges</h4>
        <p>If you are not satisfied with your purchase, you may return it within 31 days from the date of purchase.</p>
        <p>Items must be unused and in their original condition.</p>
        <p>Shipping fees for returns are the responsibility of the customer unless the return is due to our error.</p>
      </div>
      <div>
        <h4>Refunds</h4>
        <p>Once your return is received and inspected, we will process your refund to the original payment method.</p>
        <p>Refunds may take 31 days to appear on your statement.</p>
      </div>
      <div>
        <h4>Contact Us</h4>
        <p>If you have any questions or concerns about our policies or your order, please contact us at onyxblackmanhwa@gmail.com.</p>
      </div>
    </div>
  );
};

export { PrivacyPolicy, ShippingPolicy, ReturnPolicy };

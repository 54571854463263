import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { db } from '../../Firebase';
import { getDocs, collection, orderBy, query, limit } from 'firebase/firestore';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function OrginalSpotlights() {
  const [loading, setLoading] = useState(false);
  const [originalsDocs, setOriginalsDocs] = useState([]);

  useEffect(() => {
    setLoading(true);

    try {
      const originalsCollectionRef = collection(db, 'originals');
      const q = query(
        originalsCollectionRef,
        orderBy('updatedAt', 'desc'), // Order by updatedAt in descending order
        limit(1) // Limit the results to the top 2
      );

      getDocs(q)
        .then((querySnapshot) => {
          const docsData = querySnapshot.docs.map((doc) => doc.data());
          setOriginalsDocs(docsData);
          console.log(docsData);
        })
        .catch((error) => {
          console.error(error.message);
        });
    } catch (error) {
      console.error(error.message);
    }

    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="card-container">
          <Container className="mb-3 p-0">
            <h2 className="mb-2">Orginals Updates</h2>
            <p>Check out updates from our Originals collections</p>
          </Container>
          <Row>
            {originalsDocs.map((doc, index) => (
              <Col key={index}>
                <Link to={`/admin/originals/${doc.slug}`}>
                  <Card style={{ width: '100%', borderRadius: '.375rem' }}>
                    <Container
                      fluid
                      className="p-0"
                      style={{
                        position: 'relative',
                        borderRadius: '.375rem',
                      }}
                    >
                      <img
                        src={doc.coverURL}
                        alt={doc.title}
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                          height: '15rem',
                          borderRadius: '.375rem',
                        }}
                      />
                      <CardBody style={{ position: 'relative', zIndex: 1 }}>
                        <CardTitle tag="h5">{doc.title}</CardTitle>
                        <CardText>{doc.author}</CardText>
                        <CardText style={{ opacity: 1, marginBottom: '1rem' }}>
                          {doc.chapters[0].chapterSynopsis.slice(0, 100)}...
                        </CardText>
                        <CardText style={{ fontSize: '80%', opacity: 0.8 }}>
                          {new Date(doc.chapters[0].createdAt.toDate()).toLocaleDateString()}
                        </CardText>
                      </CardBody>
                    </Container>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}

import React from 'react';
import { Container, Card, Image, CardImg } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ManhwaCard(props) {

  return (
    <Card style={{ width: '100%', maxWidth: '18rem' }}>
      <Container
        fluid
        className='p-0'
        style={{
          height: '16rem'
        }}
        align='center'
      >
        <img referrerPolicy="no-referrer" src={props.image || null} style={{height: 'auto', width: 'auto', maxHeight: '16rem', objectFit: 'contain', objectPosition: 'center'}} />
      </Container>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
      </Card.Body>
    </Card>
  );
}

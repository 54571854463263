import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase'
import Loading from 'components/Loading/Loading';
import { 
    Button,
    Container,
    Col,
    Card,
    Row,
    Table } from 'react-bootstrap';
import OriginalsPages from './OriginalsPages';
import HomeNavbar from 'components/HomeNavbar/HomeNavbar';
import { UserContextData } from 'contexts/UserContext';
import { arrayUnion, updateDoc, doc } from 'firebase/firestore';

export default function OriginalsSeries() {
    const [loading, setLoading] = useState(false);
    const [originalsDoc, setOriginalsDoc] = useState(null);

    const { currentUser, userDoc, setUserDoc } = UserContextData();
  
    const { name } = useParams();
  
    useEffect(() => {
      setLoading(true);
  
      const fetchOriginalsDoc = async () => {
        try {
          const originalsCollectionRef = collection(db, 'originals');
          const q = query(originalsCollectionRef, where('slug', '==', name));
          const querySnapshot = await getDocs(q);
  
          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();
            setOriginalsDoc(docData);
            console.log(docData)
          }
        } catch (error) {
          console.error(error.message);
        }
  
        setLoading(false);
      };
  
      fetchOriginalsDoc();
    }, [name]);

    const toggleFavorites = async () => {
      try {
        // Check if user is logged in
        if (!currentUser) {
          return;
        }
    
        // Check if webtoon data is available
        if (!originalsDoc) {
          console.log('Orginals series data not available');
          return;
        }
    
        // Check if userDoc is available
        if (!userDoc) {
          console.log('User document not available');
          return;
        }
    
        // Check if the series is already in user's favorites
        const isAlreadyInFavorites = userDoc.favorites.some(
          (favorite) => favorite.slug === originalsDoc.slug
        );
    
        // Prepare data to add to or remove from favorites
        const favoriteData = {
          slug: originalsDoc.slug,
          title: originalsDoc.title,
          coverURL: originalsDoc.coverURL,
          updatedAt: originalsDoc.updatedAt,
          isOriginal: true
        };
    
        if (isAlreadyInFavorites) {
          // Remove series from favorites
          await updateDoc(doc(db, 'users', currentUser.uid), {
            favorites: userDoc.favorites.filter(
              (favorite) => favorite.slug !== originalsDoc.slug
            ),
          });
    
          // Update userDoc state
          const updatedUserDoc = { ...userDoc };
          updatedUserDoc.favorites = updatedUserDoc.favorites.filter(
            (favorite) => favorite.slug !== originalsDoc.slug
          );
          setUserDoc(updatedUserDoc);
    
          console.log('Removed from favorites successfully');
        } else {
          // Add series to favorites
          await updateDoc(doc(db, 'users', currentUser.uid), {
            favorites: arrayUnion(favoriteData),
          });
    
          // Update userDoc state
          const updatedUserDoc = { ...userDoc };
          updatedUserDoc.favorites.push(favoriteData);
          setUserDoc(updatedUserDoc);
    
          console.log('Added to favorites successfully');
        }
      } catch (error) {
        console.error('Error updating favorites:', error.message);
      }
    };

  
    if (loading) {
      return (
        <Loading />
      );
    }

    if(!currentUser && !loading){
    return(
      <>
      <HomeNavbar />
      <Container className='mt-5 align-items-center justify-content-center'>
        <p>You need to login.</p>
      </Container>
      </>
    )
  }
  
    if (!originalsDoc) {
      return (
        <div className='content'>
          <Container fluid>
            <p>You need to select a valid series.</p>
            <Link to="/admin/originals">
              <Button>
                Originals
              </Button>
            </Link>
          </Container>
        </div>
      );
    }

  const cleanSynopsis = originalsDoc?.synopsis.replace(/<br\s*\/?>/g, ' ') || '';

  
  return (
    <>
    <HomeNavbar />
    <div className='wrapper'>
        <Container>
            <Row className='mt-5'>
                <Col xs={12} md={5} style={{ height: '30rem' }}>
                    <Container fluid style={{ backgroundImage: `url(${originalsDoc.coverURL})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '100%', maxHeight: '30rem', borderRadius: '.375rem' }} className='p-0'></Container>
                </Col>
                <Col xs={12} md={7}>
                    <Container fluid className='p-0'>
                        <h2>{originalsDoc.title}</h2>
                        <p>{cleanSynopsis}</p>
                    </Container>
                    <Container fluid className='p-0 d-flex justify-content-center'>
                    <Button onClick={toggleFavorites}>
                        {userDoc.favorites.some((favorite) => favorite.slug === originalsDoc.slug)
                          ? 'Remove from Favorites'
                          : 'Add to Favorites'}
                      </Button>
                        <Link style={{marginLeft: 'auto'}} to="/admin/originals">
                          <Button variant='secondary' className='w-100'>
                               <i className='tim-icons icon-minimal-left' />
                            </Button>
                        </Link>
                    </Container>
                    <Card className='w-100' style={{maxWidth: '40rem'}}>
                    <Card.Header>
                      <Card.Title tag="h4">Chapter List</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Chapter</th>
                            <th>Title</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {originalsDoc.chapters.map((chapter, index) => (
                            <tr key={index}>
                              <td>{chapter.chapterNumber}</td>
                              <td>
                              <Link to={`/admin/originals/${name}/chapter/${chapter.chapterNumber}`}>
                                <p>{chapter.title}</p>
                              </Link>
                              </td>
                              <td>{new Date(chapter.createdAt.seconds * 1000).toLocaleDateString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
    </>
  )
}

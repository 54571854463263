import React, { useState } from 'react';
import { PrivacyPolicy, ShippingPolicy, ReturnPolicy } from './Policies'; // Import your policy components
import { Nav, NavLink, Button } from 'react-bootstrap';

const PolicyDisplay = () => {
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  // Function to handle closing the policy content
  const handleClosePolicy = () => setSelectedPolicy(null);

  return (
    <div className="mt-3 p-0">
      <Nav fluid className="p-0">
        <NavLink>
          <p onClick={() => setSelectedPolicy('privacy')}>Privacy Policy</p>
        </NavLink>
        <NavLink>
          <p onClick={() => setSelectedPolicy('shipping')}>Shipping Policy</p>
        </NavLink>
        <NavLink>
          <p onClick={() => setSelectedPolicy('return')}>Return Policy</p>
        </NavLink>
      </Nav>

      <div>
        {/* Conditionally render the selected policy */}
        {selectedPolicy === 'privacy' && (
          <div>
            <PrivacyPolicy />
            <Button variant='secondary' className='mt-3' onClick={handleClosePolicy}>Close</Button>
          </div>
        )}
        {selectedPolicy === 'shipping' && (
          <div>
            <ShippingPolicy />
            <Button variant='secondary' className='mt-3' onClick={handleClosePolicy}>Close</Button>
          </div>
        )}
        {selectedPolicy === 'return' && (
          <div>
            <ReturnPolicy />
            <Button variant='secondary' className='mt-3' onClick={handleClosePolicy}>Close</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicyDisplay;

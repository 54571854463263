import React, {useState} from 'react';
import { Nav, Navbar, NavLink } from 'react-bootstrap';
import classNames from 'classnames';

function GenreNavBar(props) {
  const { genres, onSelectGenre } = props;
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [color, setColor] = useState("navbar-transparent");

{/*}
  useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  {*/}
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  return (
    <Navbar expand="lg" className={classNames("p-0 mb-5", color)} fluid>
      <div className='navbar-toggle d-inline mr-auto' onClick={toggleCollapse}>
        <span className="navbar-toggler-bar bar1" />
        <span className="navbar-toggler-bar bar2" />
        <span className="navbar-toggler-bar bar3" />
      </div>
      <Navbar.Collapse id="navbarScroll" className={classNames({ show: collapseOpen })}>
        <Nav navbar>
          {genres.map((genre) => (
            <NavLink key={genre} to="#" onClick={() => onSelectGenre(genre.toLowerCase())} className="nav-link">
              <p>{genre}</p>
            </NavLink>
          ))}
          <NavLink key={'All'} to="#" onClick={() => onSelectGenre()} className="nav-link">
            <p>All</p>
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default GenreNavBar;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'react-bootstrap';

const CartItem = ({ item, onUpdateCartQty, onRemoveFromCart, loading }) => {
    const [updatingQuantity, setUpdatingQuantity] = useState(false);

    const handleUpdateCartQty = async (lineItemId, quantity) => {
        if (updatingQuantity) {
            return; // Don't allow updates while another update is in progress
        }

        setUpdatingQuantity(true); // Set updating state to true when starting an update

        try {
            await onUpdateCartQty(lineItemId, quantity);
        } catch (error) {
            console.error('There was an error updating the item quantity', error);
        } finally {
            setUpdatingQuantity(false); // Set updating state back to false when the update is complete
        }
    }

    const handleRemoveFromCart = () => {
        onRemoveFromCart(item.id);
    }

    return (
        <Container fluid className="cart-item mb-3 d-flex justify-content-center align-items-center flex-column flex-sm-row">
            <div className="d-flex align-items-center">
                {/*}
                <img className="cart-item__image" src={item.image.url} alt={item.name} style={{ height: '85px', width: '95px' }} />
                {*/}
                <div className="ml-3">
                    <h4 className="cart-item__details-name mb-1">{item.name}</h4>
                    <div className="cart-item__details-price">{item.line_total.formatted_with_symbol}</div>
                    <div className="cart-item__details-variants">
                        {item.selected_options.map((option) => {
                            return(
                                <p className="cart-item__details-variant">{option.group_name} : {option.option_name}</p>
                            )
                        })}
                    </div>
                </div>
            </div>

            <Container className="cart-item__details p-0 mt-3 mt-sm-0">
                <Container fluid className="d-flex justify-content-center cart-item__details-qty mx-auto align-items-center">
                    <Button
                        className="w-auto h-auto p-3"
                        onClick={() =>
                            !loading && !updatingQuantity
                                ? item.quantity > 1
                                    ? handleUpdateCartQty(item.id, item.quantity - 1)
                                    : handleRemoveFromCart()
                                : null
                        }
                        disabled={loading || updatingQuantity}
                    >
                        -
                    </Button>
                    <p className='mx-2'>{item.quantity}</p>
                    <Button
                        className="w-auto h-auto p-3"
                        onClick={() =>
                            !loading && !updatingQuantity
                                ? handleUpdateCartQty(item.id, item.quantity + 1)
                                : null
                        }
                        disabled={loading || updatingQuantity}
                    >
                        +
                    </Button>
                </Container>
            </Container>
            <i className='tim-icons icon-trash-simple mt-3 mt-sm-0' style={{ fontSize: '1.5rem', color: 'red' }} onClick={handleRemoveFromCart} />
        </Container>
    );
};

CartItem.propTypes = {
    item: PropTypes.object,
    onUpdateCartQty: PropTypes.func,
    onRemoveFromCart: PropTypes.func,
    onEmptyCart: PropTypes.func,
    loading: PropTypes.bool, // Pass the loading prop to disable buttons
};

export default CartItem;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Container, Button, Row, Col } from 'react-bootstrap';
import Loading from 'components/Loading/Loading';
import ChapterList from 'components/ChapterList/ChapterList';
import HomeNavbar from 'components/HomeNavbar/HomeNavbar';
import { UserContextData } from 'contexts/UserContext';
import { arrayUnion, updateDoc, doc } from 'firebase/firestore';
import { db } from "../Firebase.js";

export default function Manhwa() {
  const [loading, setLoading] = useState(false);
  const [webtoonData, setWebtoonData] = useState(null);
  const [webtoonChapterList, setWebtoonChapterList] = useState()

  const [count, setCount] = useState(1)

  const { currentUser, userDoc, setUserDoc } = UserContextData()

  const { name } = useParams();

  const apiKey = "b50fe9c009msh1b8a4ec2dd9170fp1507fdjsnc27faf1f1983";

  useEffect(() => {
    pullWebtoon();
    //pullWebtoonChapterList(); // Fetch webtoon chapter list
  }, []);

  const pullWebtoon = async () => {

    if(!name || name === ":name"){
      return
    }
    // Sets axios requirements
    const options = {
      method: 'GET',
      url: `https://manhwaclan-api2.p.rapidapi.com/manga/${name}`,
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': "manhwaclan-api2.p.rapidapi.com",
        'Access-Control-Allow-Credentials': "*",
      }
    };

    // Sets loading on
    setLoading(true);

    // Axios call for data
    await axios.request(options).then(function (response) {
      console.log(response.data);
      setWebtoonData(response.data);
    }).catch(function (error) {
      console.error(error);
    });

    // Sets loading off
    setLoading(false);
  }
{/*
  const pullWebtoonChapterList = async () => {
     // Sets axios requirements
     const options = {
      method: 'GET',
      url: `https://manga-scrapper.p.rapidapi.com/chapters/${name}`,
      params: {
        provider: 'omega',
        page: {count},
        limit: '20'
      },
      headers: {
        'X-RapidAPI-Key': apiKey,
        'X-RapidAPI-Host': "manga-scrapper.p.rapidapi.com",
        'Access-Control-Allow-Credentials': "*",
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization'
      }
    };

    try {
      const response = await axios.request(options);
      const sortedChapters = response.data.sort((a, b) => b.chapterNum - a.chapterNum);
      setWebtoonChapterList(sortedChapters); // Set webtoonChapterList
    } catch (error) {
      console.error(error);
    }
  };
*/}
  const addCount = () => {
    setCount(count + 1)
  }

  const subCount = () => {
    if(count = 1){
      return
    }
    setCount(count -1)
  }

  const toggleFavorites = async () => {
    try {
      // Check if user is logged in
      if (!currentUser) {
        return;
      }
  
      // Check if webtoon data is available
      if (!webtoonData) {
        console.log('Webtoon data not available');
        return;
      }
  
      // Check if userDoc is available
      if (!userDoc) {
        console.log('User document not available');
        return;
      }
  
      // Check if the series is already in user's favorites
      const isAlreadyInFavorites = userDoc.favorites.some(
        (favorite) => favorite.slug === webtoonData.id
      );
  
      // Prepare data to add to or remove from favorites
      const favoriteData = {
        slug: webtoonData.id,
        title: webtoonData.title,
        isOriginal: false
      };
  
      if (isAlreadyInFavorites) {
        // Remove series from favorites
        await updateDoc(doc(db, 'users', currentUser.uid), {
          favorites: userDoc.favorites.filter(
            (favorite) => favorite.slug !== webtoonData.id
          ),
        });
  
        // Update userDoc state
        const updatedUserDoc = { ...userDoc };
        updatedUserDoc.favorites = updatedUserDoc.favorites.filter(
          (favorite) => favorite.slug !== webtoonData.id
        );
        setUserDoc(updatedUserDoc);
  
        console.log('Removed from favorites successfully');
      } else {
        // Add series to favorites
        await updateDoc(doc(db, 'users', currentUser.uid), {
          favorites: arrayUnion(favoriteData),
        });
  
        // Update userDoc state
        const updatedUserDoc = { ...userDoc };
        updatedUserDoc.favorites.push(favoriteData);
        setUserDoc(updatedUserDoc);
  
        console.log('Added to favorites successfully');
      }
    } catch (error) {
      console.error('Error updating favorites:', error.message);
    }
  };


  
  if(!currentUser && !loading){
    return(
      <>
      <HomeNavbar />
      <Container className='content mt-5 align-items-center justify-content-center'>
        <p>You need to login.</p>
      </Container>
      </>
    )
  }

  if (!name || name === ":name" || name === undefined) {
    return (
      <>
        <div className="content">
          <p>You need to select a manhwa.</p>
          <Link to="/admin">
            <Button to>Dashboard</Button>
          </Link>
        </div>
      </>
    );
  }

  const cleanSynopsis = webtoonData?.summary.replace(/<br\s*\/?>/g, ' ') || '';

  return (
    <div className="content">
      <HomeNavbar />
      <Container className='mt-5'>
        {loading ?
          <>
            <Loading />
          </>
          :
          <>
            {!webtoonData ?
              <>
                <p>No data</p>
              </>
              :
              <>
                <Row>
                  <Col xs={12} md={5}>
                  <Container fluid className='p-0'>
                      <h2>{webtoonData.title}</h2>
                      <p>{webtoonData.summary}</p>
                      </Container>
                      <Container fluid className='p-0 d-flex justify-content-center'>
                      <Button onClick={toggleFavorites}>
                        {userDoc.favorites.some((favorite) => favorite.slug === webtoonData.id)
                          ? 'Remove from Favorites'
                          : 'Add to Favorites'}
                      </Button>
                      <Link style={{marginLeft: 'auto'}} to="/admin/browse">
                            <Button variant='secondary' className='w-100'>
                               <i className='tim-icons icon-minimal-left' />
                            </Button>
                        </Link>
                    </Container>
                  </Col>
                  <Col xs={12} md={7}>
                    
                    <ChapterList slug={webtoonData.id} chapterList={webtoonData.chapters} />
                    
                  </Col>
                </Row>
              </>
            }
          </>
        }
      </Container>
    </div>
  )
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*
const firebaseConfig = {
  apiKey: "AIzaSyBtR9h0EQW64GCDMw_c-Cqj1i0PmniPLio",
  authDomain: "manga-app-f1b52.firebaseapp.com",
  projectId: "manga-app-f1b52",
  storageBucket: "manga-app-f1b52.appspot.com",
  messagingSenderId: "775838869327",
  appId: "1:775838869327:web:ec8da32b9516d49be0873a",
  measurementId: "G-E19ZVB9JGV"
};
*/
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CLIENT_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CLIENT_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_CLIENT_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CLIENT_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CLIENT_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_CLIENT_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_CLIENT_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
const analytics = getAnalytics(app);
import { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";

const APIContext = createContext(null)

export function APIContextProvider({children}) {
    // GLOBAL DATA STATE

    // Webtoon API Data
    const [webtoonListData, setWebtoonListData] = useState([])
    const [webtoonChapterListData, setWebtoonChapterListData] = useState([])
    const [webtoonData, setWebtoonData] = useState([])

    // Loading and Error Screen
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    // Count functions for webtoon list
    const [count, setCount] = useState(1);
    const countPlus = () => {
        setCount(count + 1)
    }
    const countMinus = () => {
        if(count === 1){
            return
        }

        setCount(count - 1)
    }

    // Pulls Asura webtoon list from api for app
    // Sets data afterwards or throws error
    const pullWebtoonList = async () => {
        // Sets axios requirements
        const options = {
            method: 'GET',
            url: 'https://manga-scrapper.p.rapidapi.comwebtoons',
            params: {
              provider: 'asura',
              page: `${count}`,
              limit: '20'
            },
            headers: {
              'X-RapidAPI-Key': process.env.MANGA_API_KEY,
              'X-RapidAPI-Host': process.env.MANGA_API_HOST
            }
          };

          // Sets loading on
          setLoading(true);
          
          // Axios call for data
          await axios.request(options).then(function (response) {
            console.log(response.data);
            setWebtoonListData(response.data);
        }).catch(function (error) {
            console.error(error);
        });

        // Sets loading off
        setLoading(false);
    }

    // Pulls specific Asura webtoon chapter list from api for app
    // Sets data afterwards or throws error
    const pullWebtoonChapterList = async (props) => {
        // Sets axios requirements
        const options = {
            method: 'GET',
            url: `${process.env.MANGA_BASE_URL}chapters/`,
            params: {
              provider: 'asura',
              webtoon: `${props.slug}`,
              page: `${count}`,
              limit: '10'
            },
            headers: {
              'X-RapidAPI-Key': process.env.MANGA_API_KEY,
              'X-RapidAPI-Host': process.env.MANGA_API_HOST
            }
          };

          // Sets loading on
          setLoading(true);
          
          // Axios call for data
          await axios.request(options).then(function (response) {
            console.log(response.data);
            setWebtoonChapterListData(response.data);
        }).catch(function (error) {
            console.error(error);
        });

        // Sets loading off
        setLoading(false);
    }

    // Pulls specific Asura webtoon chapter from api for app
    // Sets data afterwards or throws error
    const pullWebtoon = async (props) => {
        // Sets axios requirements
        const options = {
            method: 'GET',
            url: `${process.env.MANGA_BASE_URL}webtoons/${props.name}`,
            params: {
              provider: 'asura',
            },
            headers: {
              'X-RapidAPI-Key': process.env.MANGA_API_KEY,
              'X-RapidAPI-Host': process.env.MANGA_API_HOST
            }
          };

          // Sets loading on
          setLoading(true);
          
          // Axios call for data
          await axios.request(options).then(function (response) {
            console.log(response.data);
            setWebtoonData(response.data);
        }).catch(function (error) {
            console.error(error);
        });

        // Sets loading off
        setLoading(false);
    }

    return(
        <APIContext.Provider value={
            {
            webtoonListData,
            webtoonChapterListData,
            webtoonData,
            errorMessage,
            loading,
            count,
            pullWebtoonList,
            pullWebtoonChapterList,
            pullWebtoon,
            countPlus,
            countMinus
            }
        }>{children}</APIContext.Provider>
    )
}

export const APIContextData = () => {
    return useContext(APIContext)
}
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { db } from '../../Firebase.js';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function OriginalsPages() {
  const { name, chapterIndex } = useParams();

  const [loading, setLoading] = useState(false);
  const [chapterData, setChapterData] = useState(null);

  const fetchChapterData = async () => {
    setLoading(true);
    try {
      const originalsCollectionRef = collection(db, 'originals');
      const q = query(
        originalsCollectionRef,
        where('slug', '==', name)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();

        // Find the chapter based on chapterIndex
        const selectedChapter = docData.chapters.find(
          (chapter) => chapter.chapterNumber === parseInt(chapterIndex)
        );

        if (selectedChapter) {
          setChapterData(selectedChapter);
        } else {
          console.error('Chapter not found.');
          setChapterData();
        }
      } else {
        console.error('Series not found.');
      }
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Fetch chapter data when the component mounts and when chapterIndex changes
    fetchChapterData();
  }, [name, chapterIndex]);

  // Function to navigate to the next chapter
  const goToNextChapter = () => {
    const nextChapterIndex = parseInt(chapterIndex) + 1;
    window.history.pushState(null, '', `/admin/originals/${name}/chapter/${nextChapterIndex}`);
    window.dispatchEvent(new Event('popstate'));
  };

  // Function to navigate to the previous chapter
  const goToPreviousChapter = () => {
    if (parseInt(chapterIndex) > 1) {
      const previousChapterIndex = parseInt(chapterIndex) - 1;
      window.history.pushState(null, '', `/admin/originals/${name}/chapter/${previousChapterIndex}`);
      window.dispatchEvent(new Event('popstate'));
    }
  };

  const formatContent = (content) => {
    console.log('Content before formatting:', content);
    const lines = content.split('\n');
    const formattedContent = [];
    let currentPanel = null;
    let currentCaptions = [];
  
    for (let line of lines) {
      if (line.startsWith('Panel')) {
        // Start of a new panel
        if (currentPanel) {
          currentPanel.captions = currentCaptions; // Assign the captions to the current panel
          formattedContent.push(currentPanel);
        }
        currentPanel = { text: line, captions: [] };
        currentCaptions = [];
      } else if (line.startsWith('Caption')) {
        // Caption for the current panel
        currentCaptions.push(line);
      } else {
        // Text within the current panel
        if (currentPanel) {
          currentPanel.text += `\n${line}`;
        }
      }
    }
    console.log('Formatted content:', formattedContent);

    // Add the last panel if exists
    if (currentPanel) {
      currentPanel.captions = currentCaptions;
      formattedContent.push(currentPanel);
    }
  
    return formattedContent;
  };
  
  const renderContentText = () => {
    if (chapterData) {
      return chapterData.content.map((page, pageIndex) => {
        const formattedContent = formatContent(page.text);
  
        return (
          <div key={pageIndex}>
            {formattedContent.map((panel, index) => (
              <Container key={index} className="p-5">
                <p>{panel.text}</p>
                {panel.captions.map((caption, i) => (
                  <p key={i}>{caption}</p>
                ))}
                <br />
                <p>Page: {page.page}</p>
              </Container>
            ))}
          </div>
        );
      });
    }
  };
  


  if (loading) {
    return (
      <div className="content mt-5" align="center">
        Loading...
      </div>
    );
  }

  if (!chapterData) {
    return (
      <>
        <div className="content mt-5" align="center">
          <p>No chapter available yet...</p>
          <div className="mt-5 mb-5">
            <Link to={`/admin/originals/${name}`}>Back to Chapter List</Link>
            <br />
            <Link to={`/admin/dashboard`}>Back to Dashboard</Link>
          </div>
        </div>
      </>
    );
  }

  return (
    <Container className="content mt-5 mb-5" align="center">
      <h2>{chapterData.title}</h2>
      <Carousel showStatus={false} showThumbs={false}>
        {renderContentText()}
      </Carousel>
      <Container className="mt-3">
        {chapterIndex > 1 && (
          <Button variant="secondary" onClick={goToPreviousChapter}>
            Previous Chapter
          </Button>
        )}
        <Button onClick={goToNextChapter}>Next Chapter</Button>
      </Container>
      <div className="mt-5 mb-5">
        <Link to={`/admin/originals/${name}`}>Back to Chapter List</Link>
        <br />
        <Link to={`/admin/dashboard`}>Back to Dashboard</Link>
      </div>
    </Container>
  );
}

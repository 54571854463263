import React, { useState } from "react";
import { Form, Button, Alert, Modal, Card } from "react-bootstrap";
import { UserContextData } from "contexts/UserContext";
import { useNavigate } from "react-router-dom";
import Loading from "components/Loading/Loading";

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [showResetModal, setShowResetModal] = useState(false);

  const {
    login,
    loading,
    reset,
    currentUser,
    loginErrorMessage,
    resetErrorMessage,
  } = UserContextData();

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Checks for email and password fields
    if ((!email, !password)) {
      alert("Please enter all information fields.");
    }

    login(email, password);
  };

  if (currentUser) {
    navigate("/admin/dashboard");
  }

  const toggleResetModal = () => {
    setShowResetModal(!showResetModal);
  };

  const handleResetPassword = async () => {
    try {
      // Get the email from the state and send the reset email
      await reset(recoveryEmail); // Assuming email is the state for the email input
    } catch (error) {
      console.error("Error sending password reset email:", error);
    }
  };

  if (currentUser) {
    return (
      <div className="content">
        <p>Redirecting to user dashboard...</p>
      </div>
    );
  }

  return (
    <Card style={{ maxWidth: "30rem" }}>
      <Card.Body>
        <Card.Title>
          <h2>Login</h2>
          <p>
            Get logged in and start reading the best manga and manhwa available
            online now!
          </p>
        </Card.Title>
        <Form className="w-100" onSubmit={handleLogin}>
          {loginErrorMessage ? (
            <Alert style={{ backgroundColor: "red" }}>
              {loginErrorMessage}
            </Alert>
          ) : (
            <></>
          )}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>

          {loading ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              <Button variant="primary" type="submit" className="w-100">
                Login
              </Button>
            </>
          )}
          <div className="mt-2">
            <p>
              Forgot Your Password?{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={toggleResetModal}
              >
                Reset Password
              </span>
            </p>
          </div>
        </Form>

        <Modal className="p-3" show={showResetModal} onHide={toggleResetModal}>
          {resetErrorMessage ? (
            <Alert style={{ backgroundColor: "red" }}>
              {resetErrorMessage}
            </Alert>
          ) : (
            <></>
          )}
          <Modal.Header>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="resetEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => {
                    setRecoveryEmail(e.target.value);
                  }} // Add a state to manage the email input
                  style={{ color: "black" }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="p-3">
            {loading ? (
              <>
                <p style={{ color: "black" }}>Sending...</p>
              </>
            ) : (
              <>
                <Button variant="secondary" onClick={toggleResetModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleResetPassword}>
                  Reset Password
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </Card>
  );
}

import React from 'react'
import { 
    Navbar, 
    Container 
    } from 'react-bootstrap'
    
export default function HomeNavbar() {
  
  return (
    <Container fluid className='p-0 mb-5'>
    <Navbar bg="dark" data-bs-theme="dark" className='pt-4 pb-4'>
        <Container fluid>
          <Navbar.Brand href="/">Onyx Black Manhwa</Navbar.Brand>
        </Container>
      </Navbar>
    </Container>
  )
}

import React, { useState, useEffect } from 'react'
import { Form, Button, Col, Row, Container, Card, Alert } from 'react-bootstrap';
import commerce from 'lib/commerce';
import { useCart } from 'contexts/CartContext';
import Loading from 'components/Loading/Loading';
import { useNavigate, Link } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import logo from '../assets/img/onyx-black-manhwa-logo.png'
import emailjs from '@emailjs/browser';

export default function Checkout() {
    const { cart, orderData, setOrderData, fetchCart, setPurchaseOrder } = useCart();
    const [loading, setLoading] = useState(false)
    const [orderCapturing, setOrderCapturing] = useState(false)
    const [orderErrorMessage, setOrderErrorMessage] = useState("")

    const [checkoutToken, setCheckoutToken] = useState({})

    const [checkoutState, setCheckoutState] = useState({
        checkoutToken: {},
        // Customer details
        firstName: '',
        lastName: '',
        email: '',
        // Shipping details
        shippingName: '',
        shippingStreet: '',
        shippingCity: '',
        shippingStateProvince: '',
        shippingPostalZipCode: '',
        shippingCountry: 'US',
        // Payment details
        cardNum: '',
        expMonth: '',
        expYear: '',
        ccv: '',
        billingPostalZipcode: '',
        // Shipping and fulfillment data
        shippingCountries: {},
        shippingSubdivisions: {},
        shippingOptions: [],
        shippingOption: "",
      });

      const navigate = useNavigate()

    useEffect(() => {
        generateCheckoutToken()
    }, [!cart])

    useEffect(() => {
        if(!checkoutToken, !checkoutState.shippingCountry, !checkoutState.shippingStateProvince){
            return
        }
        fetchShippingOptions(checkoutToken.id, checkoutState.shippingCountry, checkoutState.shippingStateProvince);
    }, [checkoutToken.id, checkoutState.shippingCountry, checkoutState.shippingStateProvince]);


    

    const handleInputChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setCheckoutState({
          ...checkoutState,
          [name]: value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
     
        // Prepare the order data based on user input
        const newOrder = {
           line_items: sanitizedLineItems(cart.line_items),
           customer: {
              firstname: checkoutState.firstName,
              lastname: checkoutState.lastName,
              email: checkoutState.email,
           },
           shipping: {
              name: checkoutState.shippingName,
              street: checkoutState.shippingStreet,
              town_city: checkoutState.shippingCity,
              county_state: checkoutState.shippingStateProvince,
              postal_zip_code: checkoutState.shippingPostalZipCode,
              country: checkoutState.shippingCountry,
           },
           fulfillment: {
              shipping_method: checkoutState.shippingOption.id,
           },
           billing: {
              name: checkoutState.shippingName, // You can update this based on your needs
              street: checkoutState.shippingStreet, // You can update this based on your needs
              town_city: checkoutState.shippingCity, // You can update this based on your needs
              county_state: checkoutState.shippingStateProvince, // You can update this based on your needs
              postal_zip_code: checkoutState.billingPostalZipcode, // You can update this based on your needs
              country: checkoutState.shippingCountry, // You can update this based on your needs
           },
           payment: {
              gateway: "paypal", // Assuming you're using Paypal
              card: {
                number: checkoutState.cardNum,
                expiry_month: checkoutState.expMonth,
                expiry_year: checkoutState.expYear,
                cvc: checkoutState.ccv,
                postal_zip_code: checkoutState.billingPostalZipcode,
              },
           },
        };
     
        // Call the function to capture the order
        handleCaptureCheckout(checkoutToken.id, newOrder);
     };
     

    /**
     *  Generates a checkout token
     *  https://commercejs.com/docs/sdk/checkout#generate-token
     */
    const generateCheckoutToken = async () => {
        setLoading(true);
    
        if (!cart.line_items) {
            return;
        }
    
        if (cart.line_items.length) {
            await commerce.checkout.generateToken(cart.id, { type: 'cart' })
                .then((token) => {setCheckoutToken(token); fetchShippingCountries(token.id);})
                //.then(() => {fetchShippingCountries(checkoutToken.id); console.log('token id should be added:', checkoutToken.id)})// Use 'token.id' here
                .catch((error) => {
                    console.log('There was an error in generating a token', error);
                });
        }
    
        setLoading(false);
    };
    

    /**
     * Fetches a list of countries available to ship to checkout token
     * https://commercejs.com/docs/sdk/checkout#list-available-shipping-countries
     *
     * @param {string} checkoutTokenId
     */
     const fetchShippingCountries = async (checkoutTokenId) => {
        await commerce.services.localeListShippingCountries(checkoutTokenId).then((countries) => {
        setCheckoutState({
            ...checkoutState, 
            shippingCountries: countries.countries,
            shippingCountry: countries.countries.US
        })
        fetchSubdivisions(checkoutState.shippingCountry)
        console.log('Shipping Countries:', countries.countries)
        }).catch((error) => {
        console.log('There was an error fetching a list of shipping countries', error);
        });
    }

    /**
     * Fetches the subdivisions (provinces/states) in a country which
     * can be shipped to for the current checkout
     * https://commercejs.com/docs/sdk/checkout#list-subdivisions
     *
     * @param {string} countryCode
     */
    const fetchSubdivisions = async (countryCode) => {
        await commerce.services.localeListSubdivisions(countryCode).then((subdivisions) => {
            setCheckoutState({
                ...checkoutState,
              shippingSubdivisions: subdivisions.subdivisions,
            })
            console.log('Subdivisions:', subdivisions, subdivisions.subdivisions)
        }).catch((error) => {
            console.log('There was an error fetching the subdivisions', error);
        });
      }

    /**
     * Fetches the available shipping methods for the current checkout
     * https://commercejs.com/docs/sdk/checkout#get-shipping-methods
     *
     * @param {string} checkoutTokenId
     * @param {string} country
     * @param {string} stateProvince
     */
    const fetchShippingOptions = async (checkoutTokenId, country, stateProvince = null) => {
        await commerce.checkout.getShippingOptions(checkoutTokenId,
        { 
            country: country,
            region: stateProvince
        }).then((options) => {
            const shippingOption = options[0] || null;

            setCheckoutState({
            ...checkoutState,
            shippingOptions: options,
            shippingOption: shippingOption,
            })
            console.log('Shipping options:', options)
        }).catch((error) => {
            console.log('There was an error fetching the shipping methods', error);
        });
    }

    const sanitizedLineItems = (lineItems) => {
        return lineItems.reduce((data, lineItem) => {
          const item = data;
          const variants = {};
      
          // Loop through selected_options to collect all selected variants
          lineItem.selected_options.forEach((option) => {
            variants[option.group_id] = option.option_id;
          });
      
          item[lineItem.id] = {
            quantity: lineItem.quantity,
            variants: variants,
          };
      
          return item;
        }, {});
      };
      

    /**
     * Refreshes to a new cart
     * https://commercejs.com/docs/sdk/cart#refresh-cart
     */
    const refreshCart = async () => {
        await commerce.cart.refresh().then((newCart) => {
        setOrderData({ 
            cart: newCart,
        });
        }).catch((error) => {
        console.log('There was an error refreshing your cart', error);
        });
    };

    const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
        console.log('Capturing order:', checkoutTokenId, newOrder);
    
        setOrderCapturing(true)

        try {
            const order = await commerce.checkout.capture(checkoutTokenId, {
                ...newOrder,
            });

            console.log('Order captured:', order);
            setPurchaseOrder(order)

            // Save the order into state
            setOrderData({
                orderData: order,
            });

            // Email notification of a sale
            sendEmail();

            // Clear the cart
            refreshCart();

            // Fetch new cart
            fetchCart()

            // Send the user to the receipt 
            navigate('/confirmation');
    
        } catch (error) {
            console.log('Error capturing order:', error);
            setOrderErrorMessage(error.data.error.message)
            setTimeout(() => {setOrderErrorMessage("")}, 3500)
        } finally{
            setOrderCapturing(false)
        }
    };

    // Define your EmailJS service ID, template ID, and user ID
    const serviceKey = process.env.REACT_APP_EMAILJS_SERVICE_KEY
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

    const sendEmail = async () => {
        const templateParams = {
            to_email: 'onyxblackmanhwa@gmail.com', // Replace with the recipient's email address
            message: `You have a new order submission from Commerce JS!`
            // Add other template parameters as needed
        };
    
        try {
            await emailjs.send(serviceKey, 'template_vqkb3bq', templateParams, publicKey);
            console.log('Email sent successfully');
        } catch (error) {
            console.error('Email sending failed:', error);
        }
        };


    // Const for card rendering below
    const renderEmptyMessage = () => {
        if (cart.total_unique_items > 0) {
          return;
        }
    
        return (
            <Container className='mt-5' align='center'>
                <p className="cart__none">
                    You have no items in your shopping cart, start adding some!
                </p>
                <Link to="/admin/shop">Back to Shop</Link>
            </Container>
        );
    }
    
    const renderItems = () => (
        cart.line_items.map((lineItem) => (
          <>
          {/*}
          <img className="cart-item__image" src={lineItem.image.url} alt={lineItem.name} style={{ height: '100px', width: '100px' }} />
        {*/}
          <div className="ml-3 mb-1">
                    <h4 className="cart-item__details-name mb-1">{lineItem.name}</h4>
                    <div className="cart-item__details-price">{lineItem.line_total.formatted_with_symbol}</div>
                    <div className="cart-item__details-variants">
                        {lineItem.selected_options.map((option) => {
                            return(
                                <p className="cart-item__details-variant">{option.group_name} : {option.option_name}</p>
                            )
                        })}
                    </div>
                </div>
                </>
          ))
    );
    
    const renderTotal = () => (
        <Container className="cart__total mt-5">
          <p className="cart__total-title">Subtotal:</p>
          <p className="cart__total-price">{cart.subtotal.formatted_with_symbol}</p>
        </Container>
    );
    

    if(loading){
        return(
            <div className='content mt-5' align='center'>
                <Loading />
            </div>
        )
    }

    if(!cart || !cart.line_items){
        return(
        <div className='content mt-5' align='center'>
            <p>Nothing here.</p>
        </div>
        )
    }
    
    return (
        <div className='content'>
            {orderErrorMessage && (<Alert style={{background: 'red'}}>{orderErrorMessage}</Alert>)}
            <Container className='mb-5 mt-5 p-0' style={{height: '7.5rem'}}>
                <img src={logo} alt="react-logo" style={{width: 'auto', height: '100%'}} />
            </Container>
            <Container className='mt-5 mb-5'>
                <h2>Checkout Details</h2>
                <Row>
                    <Col xs={12} md={7}>
                        <Form className="checkout__form" onSubmit={handleSubmit} style={{width: '100%', maxWidth: '40rem'}}>
                        <h4 className="checkout__subheading">Customer information</h4>
                    
                        <Form.Group controlId="firstName">
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                            type="text"
                            name="firstName"
                            value={checkoutState.firstName}
                            onChange={handleInputChange}
                            placeholder="Enter your first name"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="lastName">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                            type="text"
                            name="lastName"
                            value={checkoutState.lastName}
                            onChange={handleInputChange}
                            placeholder="Enter your last name"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                            type="email"
                            name="email"
                            value={checkoutState.email}
                            onChange={handleInputChange}
                            placeholder="Enter your email"
                            required
                            />
                        </Form.Group>
                    
                        <h4 className="checkout__subheading mt-4">Shipping details</h4>
                    
                        <Form.Group controlId="shippingName">
                            <Form.Label>Full name</Form.Label>
                            <Form.Control
                            type="text"
                            name="shippingName"
                            value={checkoutState.shippingName}
                            onChange={handleInputChange}
                            placeholder="Enter your shipping full name"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="shippingStreet">
                            <Form.Label>Street address</Form.Label>
                            <Form.Control
                            type="text"
                            name="shippingStreet"
                            value={checkoutState.shippingStreet}
                            onChange={handleInputChange}
                            placeholder="Enter your street address"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="shippingCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                            type="text"
                            name="shippingCity"
                            value={checkoutState.shippingCity}
                            onChange={handleInputChange}
                            placeholder="Enter your city"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="shippingStateProvince">
                            <Form.Label>State/Province</Form.Label>
                            <Form.Control
                            type="text"
                            as="select"
                            name="shippingStateProvince"
                            value={checkoutState.shippingStateProvince}
                            onChange={handleInputChange}
                            placeholder="Enter your state/province"
                            required
                            >
                                <option className="checkout__option" disabled>Select Your State</option>
                                {
                                    Object.keys(checkoutState.shippingSubdivisions).map((index) => {
                                    return (
                                        <option value={index} key={index}>{index}</option>
                                    );
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    
                        <Form.Group controlId="shippingPostalZipCode">
                            <Form.Label>Postal/Zip code</Form.Label>
                            <Form.Control
                            type="text"
                            name="shippingPostalZipCode"
                            value={checkoutState.shippingPostalZipCode}
                            onChange={handleInputChange}
                            placeholder="Enter your postal/zip code"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="shippingCountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Text>Worldwide Shipping coming soon</Form.Text>
                            <Form.Control
                            type="text"
                            name="shippingCountry"
                            value={checkoutState.shippingCountry}
                            onChange={handleInputChange}
                            placeholder="Enter your country"
                            disabled
                            required
                            />
                        </Form.Group>
                    
                        <h4 className="checkout__subheading mt-4">Payment information</h4>
                    
                        <Form.Group controlId="cardNum">
                            <Form.Label>Credit card number</Form.Label>
                            <Form.Control
                            type="text"
                            name="cardNum"
                            value={checkoutState.cardNum}
                            onChange={handleInputChange}
                            placeholder="Enter your card number"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="expMonth">
                            <Form.Label>Expiry month</Form.Label>
                            <Form.Control
                            type="text"
                            name="expMonth"
                            value={checkoutState.expMonth}
                            onChange={handleInputChange}
                            placeholder="Card expiry month"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="expYear">
                            <Form.Label>Expiry year</Form.Label>
                            <Form.Control
                            type="text"
                            name="expYear"
                            value={checkoutState.expYear}
                            onChange={handleInputChange}
                            placeholder="Card expiry year"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="ccv">
                            <Form.Label>CCV</Form.Label>
                            <Form.Control
                            type="text"
                            name="ccv"
                            value={checkoutState.ccv}
                            onChange={handleInputChange}
                            placeholder="CCV (3 digits)"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="billingPostalZipcode">
                            <Form.Label>Billing Postal/Zip code</Form.Label>
                            <Form.Control
                            type="text"
                            name="billingPostalZipcode"
                            value={checkoutState.billingPostalZipcode}
                            onChange={handleInputChange}
                            placeholder="Enter your billing postal/zip code"
                            required
                            />
                        </Form.Group>
                    
                        <Form.Group controlId="shippingOptions">
                            <Form.Label>Shipping Options</Form.Label>
                            <Form.Control
                                type="text"
                                as="select"
                                name="shippingOption"
                                value={checkoutState.shippingOption} 
                                onChange={handleInputChange}
                                required
                            >
                                <option className="checkout__select-option" disabled>Select a shipping method</option>
                                {
                                    checkoutState.shippingOptions.map((method, index) => {
                                    return (
                                        <option className="checkout__select-option" value={method.id} key={index}>{`${method.description} - $${method.price.formatted_with_code}` }</option>
                                    );
                                    })
                                };
                            </Form.Control>
                            </Form.Group>
                                
                            <Button
                                className="checkout__btn-confirm mt-3"
                                type="submit"
                                disabled={orderCapturing}
                            >
                                {orderCapturing ? "Confirming Order" : "Submit Order"}
                            </Button>
                            {orderErrorMessage && (<Alert style={{background: 'red'}}>{orderErrorMessage}</Alert>)}
                        </Form>
                    </Col>
                    <Col xs={12} md={5}>   
                        <Card className="cart">
                            <Card.Body>
                            <Card.Title>
                                <h4 className="cart__heading">Your Shopping Cart</h4>
                            </Card.Title>
                            { renderEmptyMessage() }
                            { renderItems() }
                            { renderTotal() }
                            <Container className="cart__footer">
                            <Link to="/admin/shop">
                                <Button variant="secondary" className="cart__btn-checkout" disabled={orderCapturing}>{orderCapturing ? "Confirming Order" : "Return To Shop"}</Button> 
                            </Link>
                            </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
      );
}

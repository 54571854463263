import React, { useState } from "react";
import { UserContextData } from "contexts/UserContext";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import Loading from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import FavoritesList from "components/FavoritesList/FavoritesList";

import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from "../Firebase.js"

function UserProfile() {
  const { currentUser, userDoc, setUserDoc, loading } = UserContextData();
  const [updatedUserDoc, setUpdatedUserDoc] = useState({}); // Initialize empty userDoc
  const [updatingUser, setUpdatingUser] = useState(false)
  
  const navigate = useNavigate()

  if(loading || !currentUser){
    <div className="content">
      <Loading />
    </div>
  }

  if(!userDoc){
    <div className="content">
      <p>Error pulling user data</p>
    </div>
  }

  const handleProfileUpdate = async () => {
    setUpdatingUser(true);
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, updatedUserDoc);
  
      // Merge updatedUserDoc with userDoc data
      const mergedUserDoc = { ...userDoc, ...updatedUserDoc };
      setUserDoc(mergedUserDoc);

      console.log(userDoc, mergedUserDoc)
  
    } catch (error) {
      console.error(error.message);
    } finally{
      setUpdatingUser(false);
    }
  };

  return (
    <div className="content">
        <Row>
        <Col md="8">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a onClick={(e) => e.preventDefault()}>
                    {userDoc.userimage ? 
                    <>
                      <img
                      alt="..."
                      className="avatar"
                      src={`${userDoc.userimage}`}
                      />
                    </> 
                    : 
                    <>
                      <p>No image</p>
                    </>
                    }
                    <h5 className="title">{userDoc.firstname}{" "}{userDoc.lastname}</h5>
                  </a>
                  <p className="description">@{userDoc.username}</p>
                </div>
                <div className="card-description" align='center'>
                  {userDoc.aboutMe != "" ? <><p>{userDoc.aboutMe}</p></> : <>...</>}
                </div>
              </CardBody>
            </Card>
            <Card align='center' className="card-user">
            <CardFooter>
              <h4>Follow Us On Social Media</h4>
                <div className="button-container">
                  <a href='https://www.tiktok.com/@onyxblackmanhwa' target='_blank'>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-tiktok" />
                  </Button>
                  </a>
                </div>
              </CardFooter>
            </Card>
            <FavoritesList maxFavoritesToShow={5000} />
          </Col>
          <Col md="4">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile Information</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="px-md-3" md="12">
                      <FormGroup>
                        <label>Username</label>
                        <Input
                          placeholder={userDoc.username}
                          type="text"
                          disabled
                          
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="pl-md-3" md="12">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input 
                          placeholder={userDoc.email}
                          type="email"
                          disabled
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          placeholder={userDoc.firstname}
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          placeholder={userDoc.lastname}
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          cols="80"
                          placeholder="Describe yourself"
                          rows="4"
                          type="textarea"
                          defaultValue={userDoc.aboutMe}
                          onChange={(e) => setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, aboutMe: e.target.value }))}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label>User Image</Label>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={async (e) => {
                            const file = e.target.files[0];
                            if (file) {
                              console.log(file)
                              const storageRef = ref(storage, `users/${currentUser.uid}`);
                              setUpdatingUser(true)
                              await uploadBytes(storageRef, file);
                              const imageUrl = await getDownloadURL(storageRef);
                              setUpdatedUserDoc(prevUserDoc => ({ ...prevUserDoc, userimage: imageUrl }));
                              setUpdatingUser(false)
                            }
                          }}
                        />
                        {userDoc.userimage ? <><br /><label>Current Image</label> <br /><img src={userDoc.userimage} alt="User" style={{ maxWidth: '100px', marginBottom: '10px' }} /></> : <> <br />No User Image Yet...</>}
                        <br />
                        <Button className="mt-3">Upload Image</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Button 
                  className="btn-fill" 
                  color="primary" 
                  type="submit" 
                  onClick={handleProfileUpdate} 
                  disabled={updatingUser}
                  >
                  {updatingUser ? "Updating" : "Update"}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
    </div>
  );
}

export default UserProfile;

import { 
    Row,
    Col,
  Container } from 'react-bootstrap'
import PropTypes from 'prop-types';
import ProductItem from 'components/ProductItem/ProductItem';
import { useEffect } from 'react';
import classNames from 'classnames';

const ProductsList = ({ products, onAddToCart, onSelectProduct, loading }) => {
  useEffect(() => {console.log(products)}, [])
    return (
    <>
      <Row fluid className='p-0'>
        {products.map((product) => {
          return (
            <Col xs={12} md={6} lg={4} xl={3}  key={product.id} className='p-2'>
              <ProductItem
                product={product}
                onAddToCart={onAddToCart}
                onSelectProduct={() => onSelectProduct(product)} // Make sure this line is present
                loading={loading} // Pass loading prop to ProductItem
              />
            </Col>
          );
        })}
      </Row>
      </>
    );
  };
  
  ProductsList.propTypes = {
    products: PropTypes.array,
    onAddToCart: PropTypes.func,
    onSelectProduct: PropTypes.func, // Make sure onSelectProduct is properly defined in the prop types
  };
  
  export default ProductsList;
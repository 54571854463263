import { createContext, useEffect, useState } from "react";

// Available themes
export const themes = {
  dark: "",
  light: "white-content",
};

// Initial context state
const initialContextState = {
  theme: themes.dark,
  changeTheme: () => {},
  provider: "flame",  // Default provider
  setProvider: () => {},  // Function to update provider
};

export const ThemeContext = createContext(initialContextState);

// ...

export const ThemeProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? JSON.parse(savedTheme) : themes.dark;
  });
  const [provider, setProvider] = useState("flame");

  useEffect(() => {
    setLoading(false);
  }, [theme]);

  const changeTheme = (selectedTheme) => {
    setTheme(selectedTheme);
    localStorage.setItem("theme", JSON.stringify(selectedTheme));
  };

  const changeProvider = (selectedProvider) => {
    setProvider(selectedProvider);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        changeTheme,
        provider,
        changeProvider,  // Corrected to use the correct function
      }}
    >
      {loading ? null : children}
    </ThemeContext.Provider>
  );
};



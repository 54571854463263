import { Container } from 'react-bootstrap'
import ContactForm from 'components/ContactForm/ContactForm'

export default function Contact() {
  return (
    <div fluid className='content'>
        <ContactForm />
    </div>
  )
}

import React, { useEffect } from 'react'
import { useCart } from 'contexts/CartContext';
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import logo from "../assets/img/onyx-black-manhwa-logo.png"

export default function Confirmation() {
    const { orderData, purchaseOrder } = useCart();


    useEffect(() => {
        console.log(purchaseOrder);
    }, [])

    if (!orderData || !purchaseOrder) {
        return (
          <div className='mt-5' align="center">
            <p>Loading...</p>
          </div>
        );
      }
    
      // Extract order details from the purchaseOrder object
      const {
        customer: { firstname, lastname, email },
        order: {
          total,
          line_items: [item],
        },
        customer_reference,
      } = purchaseOrder;
    
      return (
        <div className="confirmation content mt-5" align='center'>
          <div className="confirmation__wrapper">
          <Container className='mb-5 mt-5 p-0' style={{height: '7.5rem'}}>
                <img src={logo} alt="react-logo" style={{width: 'auto', height: '100%'}} />
            </Container>
            <div className="confirmation__wrapper-message">
              <h2>Thank you for your purchase, {firstname} {lastname}!</h2>
              <p>Your support of Onyx Black Manhwa is truly appreciated. As a small business and organization, we want to say thank you!</p>
              <p className="confirmation__wrapper-reference">
                You'll receive an email with shipping confirmation of your order at {email}.
              </p>
    
              {/* Display order details */}
              <h3 className='mt-3'>Order Details:</h3>
                    <p>Product: <span style={{textDecoration: 'underline', fontSize: '18px'}}>{item.product_name}</span></p>
                    <p>Quantity: <span style={{textDecoration: 'underline', fontSize: '18px'}}>{item.quantity}</span></p>
                    <p>Order Total: <span style={{textDecoration: 'underline', fontSize: '18px'}}>{total.formatted_with_symbol}</span></p>
                    <p>Order Receipt: <span style={{textDecoration: 'underline', fontSize: '18px'}}>{customer_reference}</span></p>
            </div>
            <div className='mt-5'>
                <Link
                className="confirmation__wrapper-back"
                to="/admin/dashboard"
                >
                <Button>Back to Dashboard</Button>
                </Link>
            </div>
          </div>
        </div>
    );
}

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate, useParams } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { APIContextProvider } from "contexts/APIContext";
import { UserContextProvider } from "contexts/UserContext";
import { CartProvider } from "contexts/CartContext";
import Home from "views/Home";
import Error from "views/Error";
import Checkout from "views/Checkout";
import OriginalsSeries from "components/Originals/OriginalsSeries";
import Manhwa from "views/Manhwa";
import Confirmation from "views/Confirmation";
import ChapterViewer from "components/ChapterViewer/ChapterViewer";
import OriginalsPages from "components/Originals/OriginalsPages";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <APIContextProvider>
        <UserContextProvider>
          <CartProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/admin/*" element={<AdminLayout />} />
                <Route path="/" element={<Home />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/confirmation" element={<Confirmation />} /> 
                {/* OriginalsSeries route with admin layout */}
                <Route
                  path="/admin/originals/:name"
                  element={<OriginalsSeries />}
                />
                <Route 
                  path="/admin/originals/:name/chapter/:chapterIndex" 
                  element={<OriginalsPages />} 
                  />
                {/* Manhwa route with admin layout */}
                <Route
                  path="/admin/manhwa/:name"
                  element={<Manhwa />}
                />
                <Route path="/admin/manhwa/:name/chapter/:chapterIndex" element={<ChapterViewer />} />
                <Route path="*" element={<Error />} />
              </Routes>
            </BrowserRouter>
          </CartProvider>
        </UserContextProvider>
      </APIContextProvider>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);
